import React, { useState } from "react"
// import { useStaticQuery, graphql } from "gatsby"
// import Img from "gatsby-image"
import { navigate } from 'gatsby-link'

import * as S from "./styled"


const Form = () => {

    const [formData, setFormData] = useState({
        name: '',
        number: '',
        email: '',
        message: '',
      })

    const encode = (data) => {
        return Object.keys(data)
          .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
          .join('&')
      }  


    const handleChange = (event) => {
        const { name, value } = event.target

        setFormData({ ...formData, [name]: value })
    }
    
    const handleSubmit = (e) => {
        e.preventDefault()
        const form = e.target

        fetch('/', {
          method: 'POST',
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          body: encode({
            'form-name': form.getAttribute('name'),
            ...formData,
          }),
        })
          .then(() => navigate(form.getAttribute('action')))
          .catch((error) => alert(error))
      }

    return (
        <>
            <S.InfosContainer>
                <S.InfosInnerContainer>
                    <S.InfosHeader>
                        <h6>Entre em Contato</h6>
                        <S.IconeDireita />
                    </S.InfosHeader>

                    <h4>R. Vinte de Setembro, 2353 <br /> Centro <br /> Caxias do Sul - RS <br /> 95020-450</h4>

                    <S.Contatos title="Whatsapp" href="https://api.whatsapp.com/send?phone=55549997108252" target="_blank" rel="noopener noreferrer" >
                        <S.IconeWhatsapp />
                        <h3>54.99710.8252</h3>
                    </S.Contatos>

                    <S.Contatos href="mailto:contato@tisatto.com.br">
                        <S.IconeEmail />
                        <h3>contato@tisatto.com.br</h3>
                    </S.Contatos>
                </S.InfosInnerContainer>
                <form 
                    name="contact"
                    method="POST"
                    action="/obrigado"
                    data-netlify="true"
                    onSubmit={handleSubmit}
                >
                    <S.Fieldset>
                        <S.FieldGroup>
                            <S.Field margin >
                                <input 
                                    type="text" 
                                    name="name" 
                                    id="name" 
                                    placeholder="NOME"
                                    required={true}
                                    onChange={handleChange}
                                    />
                            </S.Field>
                            <S.Field>
                                <input type="number" name="number" id="number" placeholder="TELEFONE" required={true} onChange={handleChange}/>
                            </S.Field>
                        </S.FieldGroup>

                        <S.Field>
                            <input type="email" name="email" id="email" placeholder="E-MAIL" required={true} onChange={handleChange} />
                        </S.Field>

                        <S.TextAreaField>
                            <S.TextArea name="message" placeholder="MENSAGEM" onChange={handleChange}></S.TextArea>
                            <button type="submit" >Enviar</button>
                        </S.TextAreaField>

                    </S.Fieldset>
                </form>

            </S.InfosContainer>
        </>
    )
}

export default Form