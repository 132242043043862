import React from "react"

import Layout from "../components/Layout"
import SEO from "../components/seo"
import Form from "../components/Form"
import Maps from "../components/Maps"

const AboutPage = () => (
  <Layout title="contato">
    <SEO title="Contact" />
    <Form />
    <Maps />
  </Layout>
)

export default AboutPage