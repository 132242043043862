import styled from 'styled-components'
import media from 'styled-media-query'

export const MapsContainer = styled.div`
    width: 90%;
    padding: 30px 20px;
    margin: 0 auto;
`

export const Title = styled.h1`
    font-family: 'Titillium Web', sans-serif;
    font-size: 3rem;
    font-weight: 700;
    color: #0C8D85;

    text-transform: uppercase;

    margin-bottom: 15px;

    ${media.lessThan('medium')`
        font-size: 1.7rem;
    `}
`