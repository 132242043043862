import React from "react"
import { Map, TileLayer, Marker, Popup } from 'react-leaflet'

import * as S from './styled'
class Maps extends React.Component {

    state = {
        position: {
            lat: -29.164894,
            lng: -51.1839964,
        }
    }

    render() {

        const finalPosition = [this.state.position.lat, this.state.position.lng]

        if (typeof window !== 'undefined') {
            return (
                <>
                    <S.MapsContainer>
                        <S.Title>Localização</S.Title>
                        <Map center={finalPosition} zoom={15} style={{ height: "40vh" }}>
                            <TileLayer
                                attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            />
                            <Marker position={finalPosition}>
                                <Popup> R. Vinte de Setembro, 2353 <br /> Centro, Caxias do Sul - RS <br /> 95020-450</Popup>
                            </Marker>
                        </Map>
                    </S.MapsContainer>
                </>
            );
        }
        return null
    }
}

export default Maps