import styled from 'styled-components'
import media from 'styled-media-query'

import { ChevronRight } from "@styled-icons/boxicons-regular/ChevronRight"
import { Whatsapp } from "@styled-icons/boxicons-logos/Whatsapp"
import { EmailOutline } from "@styled-icons/evaicons-outline/EmailOutline"

export const InfosContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    
    margin-top: 100px;
    margin-bottom: 100px;


    ${media.lessThan("medium")`
        flex-direction: column;
    `}
    
    form {
        width: 40%;

        margin-left: 200px;
        
        display: flex;
        flex-direction: column;

        font-family: 'Montserrat', sans-serif;

        ${media.lessThan("medium")`
            margin: 50px 0;
            width: 90%;
        `} 
    }    

`

export const InfosInnerContainer = styled.div`

    h4 {
        
        font-family: 'Montserrat', sans-serif;
        font-weight: 700;
        font-size: 0.9rem;
        color: #58595B;
        line-height: 20px;

        margin-bottom: 50px;

    }
`

export const InfosHeader = styled.div`
    font-family: 'Titillium Web', sans-serif;
    font-size: 1.3rem;
    font-weight: 700;
    color: #2D60AB;

    margin-bottom: 50px;

    h6 {
        display: inline;
        margin-right: 10px;
    }
`

export const IconeDireita = styled(ChevronRight)`
    width: 30px;
    color: #0C8E86;

    position: relative;
    top: -1.5px;
`

export const Contatos = styled.a`
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-size: 0.9rem;
    color: #2D60AB;

    display: flex;
    flex-direction: row;
    align-items: center;

    cursor: pointer;

    margin-bottom: 10px;

    h3 {
        margin-left: 5px;

    }
`

export const IconeWhatsapp = styled(Whatsapp)`
    width: 30px;
    color: #0C8E86;
`

export const IconeEmail = styled(EmailOutline)`
    width: 28px;
    color: #0C8E86;
`

export const Fieldset = styled.fieldset`
    min-inline-size: auto;
    border: 0;

`

export const FieldGroup = styled.div`
    flex: 1;
    display: flex;
    flex-wrap: wrap;

`

export const Field = styled.div`
    flex: 1;

    display: flex;
    flex-direction: column;
    margin-bottom: 24px;

    border: 2px solid rgba( 0, 0, 0, 0.2);
    border-radius: 20px;

    padding: 10px 20px;

    margin-right: ${ props => props.margin ? "10px" : "0" };

    input {
        font-family: 'Montserrat', sans-serif;
        font-weight: 700;
        color: #58595B;
        border: none;
        outline: none;
    }

    ${media.lessThan("1214px")`
        margin-right: 0;
    `}
`

export const TextAreaField = styled.div`
    border: 2px solid rgba( 0, 0, 0, 0.2);
    border-radius: 20px;

    padding: 10px 20px;

    display: flex;
    flex-direction: column;
    align-items: flex-end;

    button {
        font-family: 'Montserrat', sans-serif;
        font-weight: 700;
        letter-spacing: 1.4px;

        padding: 10px 20px;
        border: none;
        background-color: #0C8E86;
        border-radius: 23px;
        color: #fff;
        text-transform: uppercase;

        cursor: pointer;
    }

`

export const TextArea = styled.textarea`
    width: 100%;
    height: 100px;
    resize: none;
    overflow: auto;
    border: none;

    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    color: #58595B;

    &:focus {
        outline: none;
    }
`
